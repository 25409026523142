import { ShopKey } from '@providers/ConfigProvider';
import { TextSection, getParagraphBlock } from '@components/SearchEngineText';

import messages from './ShopSearchEngineText.messages';

export const textMap: Record<ShopKey, TextSection[]> = {
  biznesowe: [
    {
      header: messages.specializedPartnerHeader,
      blocks: [getParagraphBlock(messages.specializedPartnerDescription)],
    },
    {
      header: messages.richOfferHeader,
      blocks: [getParagraphBlock(messages.richOfferDescription)],
    },
    {
      header: messages.factoryNewHeader,
      blocks: [
        getParagraphBlock(messages.factoryNewDescriptionFirstPart),
        getParagraphBlock(messages.factoryNewDescriptionSecondPart),
      ],
    },
    {
      header: messages.freeDeliveryHeader,
      blocks: [getParagraphBlock(messages.freeDeliveryDescription)],
    },
    {
      header: messages.preorderHeader,
      blocks: [getParagraphBlock(messages.preorderDescription)],
    },
  ],
  dellowo: [
    {
      header: messages.preferredOfficialPartnerHeader,
      blocks: [getParagraphBlock(messages.preferredOfficialPartnerDescription)],
    },
    {
      header: messages.hardwareForCompaniesHeader,
      blocks: [getParagraphBlock(messages.hardwareForCompaniesDescription)],
    },
    {
      header: messages.dellFactoryNewHeader,
      blocks: [
        getParagraphBlock(messages.dellFactoryNewDescriptionFirstPart),
        getParagraphBlock(messages.dellFactoryNewDescriptionSecondPart),
      ],
    },
    {
      header: messages.dellowoFreeDeliveryHeader,
      blocks: [getParagraphBlock(messages.dellowoFreeDeliveryDescription)],
    },
    {
      header: messages.dellowoPreorderHeader,
      blocks: [getParagraphBlock(messages.dellowoPreorderDescription)],
    },
    {
      header: messages.titanPartnerHeader,
      blocks: [getParagraphBlock(messages.titanPartnerDescription)],
    },
  ],
  premiumStation: [],
  premiumSerwer: [],
  cocon: [],
  firewall: [
    {
      header: messages.welcomeFirewallHeader,
      blocks: [
        getParagraphBlock(messages.welcomeFirewallDescriptionFirstPart),
        getParagraphBlock(messages.welcomeFirewallDescriptionSecondPart),
      ],
    },
    {
      header: messages.firewallSpecializedHeader,
      blocks: [getParagraphBlock(messages.firewallSpecializedDescription)],
    },
    {
      header: messages.firewallEquipmentHeader,
      blocks: [getParagraphBlock(messages.firewallEquipmentDescription)],
    },
    {
      header: messages.firewallGuaranteeHeader,
      blocks: [getParagraphBlock(messages.firewallGuaranteeDescription)],
    },
    {
      header: messages.firewallShipmentHeader,
      blocks: [getParagraphBlock(messages.firewallShipmentDescription)],
    },
    {
      header: messages.firewallSupportHeader,
      blocks: [getParagraphBlock(messages.firewallSupportDescription)],
    },
    {
      header: messages.firewallPartnersHeader,
      blocks: [getParagraphBlock(messages.firewallPartnersDescription)],
    },
  ],
  rugged: [],
};
