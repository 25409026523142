import { ElementType, ReactNode } from 'react';
import classNames from 'classnames';

import Message from 'types/Message';
import LargeHeading from '@components/atoms/LargeHeading';
import createStyleVariables from 'utils/createStyleVariables';

import AdvantageItem from './components/AdvantageItem';
import classes from './AdvantagesSection.module.scss';

type Props = {
  title?: Message;
  secondTitle?: Message;
  subtitle?: Message;
  children: ReactNode[];
  itemsPerRow?: number;
  className?: string;
  advantagesClassName?: string;
  titleRenderAs?: ElementType;
  secondTitleRenderAs?: ElementType;
};

const AdvantagesSection = ({
  children,
  title,
  secondTitle,
  subtitle,
  itemsPerRow,
  className,
  advantagesClassName,
  titleRenderAs,
  secondTitleRenderAs,
}: Props): JSX.Element => (
  <section className={className}>
    {title && (
      <div className={classes.headerWrapper}>
        <LargeHeading
          title={title}
          secondTitle={secondTitle}
          subtitle={subtitle}
          titleRenderAs={titleRenderAs}
          secondTitleRenderAs={secondTitleRenderAs}
        />
      </div>
    )}
    <div
      className={classNames(classes.advantages, advantagesClassName)}
      style={createStyleVariables({ childrenLength: itemsPerRow || children.length })}
    >
      {children}
    </div>
  </section>
);

AdvantagesSection.Item = AdvantageItem;

export default AdvantagesSection;
