import { MappedCart } from 'types/MappedCart';
import { defaultPrice } from 'consts/price';

export const defaultCart: MappedCart = {
  id: -1,
  totalPrice: defaultPrice,
  totalGrossPrice: defaultPrice,
  quantity: 0,
  hasTeacherPromo: false,
  items: [],
};
