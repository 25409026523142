import { useContext } from 'react';

import { ConfigContext } from '@providers/ConfigProvider';
import { FormattedMessage } from 'utils/intl';

import ShopBanner from '../ShopBanner';
import messages from './FirewallBanner.messages';

const FirewallBanner = (): JSX.Element => {
  const { contact } = useContext(ConfigContext);

  return (
    <ShopBanner
      images={['engage', 'fortinet-lan', 'fortinet-sdwan']}
      header={<FormattedMessage {...messages.header} values={{ shop: contact.appShortName }} />}
      description={<FormattedMessage {...messages.description} />}
    />
  );
};

export default FirewallBanner;
