import Image from '@next/image';

import { FooterLogo } from 'types/models/FooterLogo';
import HeroSection from '@components/molecules/HeroSection';
import { FormattedMessage } from 'utils/intl';

import messages from './RuggedBanner.messages';
import classes from './RuggedBanner.module.scss';

type Props = {
  logos: FooterLogo[];
};

const RuggedBanner = ({ logos }: Props) => (
  <div className={classes.wrapper}>
    <HeroSection
      title={<FormattedMessage {...messages.title} />}
      subtitle={<FormattedMessage {...messages.subtitle} />}
    />
    <div className={classes.logos}>
      {logos.map(({ id, description, file }) => (
        <Image
          key={id}
          height={120}
          objectFit="contain"
          alt={description}
          resolveConfig={{
            preset: 'footer_logo',
            description,
            productName: 'footer_logo',
            extension: file.extension,
            name: file.basename,
            width: file.width,
            height: file.height,
          }}
          hasLazyLoad={false}
          hasPreload
        />
      ))}
    </div>
  </div>
);

export default RuggedBanner;
