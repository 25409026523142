import { useContext } from 'react';
import Image from '@next/image';
import classNames from 'classnames';

import Message from 'types/Message';
import { Classes } from 'types/Classes';
import { FilesContext } from '@providers/FilesProvider';
import Typography from '@components/atoms/Typography';

import classes from './HeroSection.module.scss';

export type Props = {
  title: Message;
  subtitle: Message;
  classes?: Classes<'wrapper' | 'container' | 'title' | 'subtitle'>;
};

const HeroSection = ({ title, subtitle, classes: customClasses }: Props): JSX.Element => {
  const { getPageImageConfigs } = useContext(FilesContext);
  const [background] = getPageImageConfigs(['background']);

  return (
    <section className={classNames(classes.wrapper, customClasses?.wrapper)}>
      <div className={classNames(classes.container, customClasses?.container)}>
        <Typography className={classNames(classes.title, customClasses?.title)} variant="h1">
          {title}
        </Typography>
        <Typography className={classNames(classes.subtitle, customClasses?.subtitle)} variant="body2" renderAs="div">
          {subtitle}
        </Typography>
      </div>
      {background && (
        <Image
          alt="background"
          layout="fill"
          objectFit="cover"
          className={classes.image}
          resolveConfig={background}
          hasLazyLoad={false}
          hasPreload
        />
      )}
    </section>
  );
};

export default HeroSection;
