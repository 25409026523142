import { useContext } from 'react';

import { ConfigContext } from '@providers/ConfigProvider';
import AdvantagesSection from '@components/molecules/AdvantagesSection';
import ExperienceIcon from 'assets/icons/experience.svg';
import OriginalIcon from 'assets/icons/original.svg';
import SecurityIcon from 'assets/icons/security.svg';
import Support2Icon from 'assets/icons/support2.svg';
import SVGComponent from 'types/SVGComponent';
import getListWithId from 'utils/getListWithId';
import { FormattedMessage, MessageDescriptor } from 'utils/intl';

import messages from './AboutCompany.messages';

type Item = {
  id: number;
  header: MessageDescriptor;
  description: MessageDescriptor;
  icon: SVGComponent;
};

const items: Item[] = getListWithId([
  {
    header: messages.experienceHeader,
    description: messages.experienceDescription,
    icon: ExperienceIcon,
  },
  {
    header: messages.originalHeader,
    description: messages.originalDescription,
    icon: OriginalIcon,
  },
  {
    header: messages.securityHeader,
    description: messages.securityDescription,
    icon: SecurityIcon,
  },
  {
    header: messages.supportHeader,
    description: messages.supportDescription,
    icon: Support2Icon,
  },
]);

const firewallItems: Item[] = getListWithId([
  {
    header: messages.experienceHeader,
    description: messages.experienceDescription,
    icon: ExperienceIcon,
  },
  {
    header: messages.convidenceHeader,
    description: messages.convidenceDescription,
    icon: OriginalIcon,
  },
  {
    header: messages.securityHeader,
    description: messages.securityDescription,
    icon: SecurityIcon,
  },
  {
    header: messages.supportHeader,
    description: messages.supportDescription,
    icon: Support2Icon,
  },
]);

const AboutCompany = (): JSX.Element => {
  const { isFirewall } = useContext(ConfigContext);

  return (
    <AdvantagesSection
      title={<FormattedMessage {...messages.mainHeader} />}
      subtitle={<FormattedMessage {...messages.mainDescription} />}
    >
      {(isFirewall ? firewallItems : items).map(({ id, header, description, icon }) => (
        <AdvantagesSection.Item
          key={id}
          header={<FormattedMessage {...header} />}
          description={<FormattedMessage {...description} />}
          icon={icon}
        />
      ))}
    </AdvantagesSection>
  );
};

export default AboutCompany;
