import Message from 'types/Message';
import { FormattedMessage, MessageDescriptor } from 'utils/intl';

type Props = {
  message: MessageDescriptor;
  values: Record<string, Message>;
};

const MessageWithBold = ({ message, values }: Props): JSX.Element => (
  <FormattedMessage
    {...message}
    values={Object.keys(values).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: <b>{values[curr]}</b>,
      }),
      {}
    )}
  />
);

export default MessageWithBold;
