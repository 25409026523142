import { Organization, WebSite, WithContext } from 'schema-dts';

import config from 'config';
import ContactConfig from 'types/ContactConfig';
import formatPhone from 'utils/formatPhone';

const { routes } = config;

export const getHomeStructuredData = (contact: ContactConfig, companyLogoUrl?: string): WithContext<Organization> => {
  const structuredData: WithContext<Organization> = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: contact.name,
    legalName: `${contact.name} ${contact.typeOfCompany}`,
    url: contact.companyUrl,
    logo: companyLogoUrl,
    image: companyLogoUrl,
    telephone: formatPhone(contact.landlinePhone, 'landline'),
    faxNumber: contact.faxNumber,
    email: contact.contactEmail,
    address: contact.address,
    areaServed: 'PL',
    sameAs: contact.links,
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: contact.servicePhoneNumber,
      contactType: 'customer service',
      areaServed: 'PL',
    },
  };
  return structuredData;
};

export const getSearchBoxStructuredData = (appUrl: string): WithContext<WebSite> => {
  const structuredData: WithContext<WebSite> = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: appUrl,
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `${appUrl}${routes.search.href}?name={search_term_string}`,
      },
      'query-input': 'required name=search_term_string',
    } as WebSite['potentialAction'] & { 'query-input': string },
  };
  return structuredData;
};
