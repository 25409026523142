import { useRouter } from 'next/router';
import Image from '@next/image';
import classNames from 'classnames';

import { ImagePreset } from 'types/ImagePreset';
import { BannerTypeId } from 'types/models/Banner';
import PossibleLink from '@components/atoms/PossibleLink';
import Slider from '@templates/Slider';
import { getBannerClickPayload } from 'utils/analytics/payload/bannerClick';
import useTrack from 'hooks/useTrack';

import PromotionBannersArrow from './components/PromotionBannersArrow';
import { Props } from './PromotionBanners.types';
import classes from './PromotionBanners.module.scss';

const presetMap: Record<BannerTypeId, ImagePreset> = {
  SMALL: 'banner_small',
  MEDIUM: 'banner_medium',
  BIG: 'banner_big',
};

const PromotionBanners = ({ classes: customClasses, type, promotions, sizes }: Props): JSX.Element => {
  const { trackLink } = useTrack();
  const router = useRouter();

  return (
    <div className={classNames(classes.container, customClasses?.root)}>
      <Slider
        slideClassName={classNames(classes.slide, customClasses?.slide)}
        leftArrow={({ onClick }) => (
          <PromotionBannersArrow
            variant="left"
            className={classNames(classes.arrow, customClasses?.arrow)}
            onClick={onClick}
          />
        )}
        rightArrow={({ onClick }) => (
          <PromotionBannersArrow
            variant="right"
            className={classNames(classes.arrow, customClasses?.arrow)}
            onClick={onClick}
          />
        )}
        initialLoaded={[true]}
        autoPlay={promotions.length > 1}
        withIndependentArrows
        lazyLoad
      >
        {promotions.map(
          ({ id, name, url, desktopImage, mobileImage, openInNewTab }, i) =>
            desktopImage &&
            mobileImage && (
              <PossibleLink
                key={id}
                className={classes.banner}
                href={url}
                openInNewTab={openInNewTab}
                trackEvent={trackLink('common', 'banner_click', getBannerClickPayload(router.asPath))}
              >
                <Image
                  layout="fill"
                  objectFit="contain"
                  alt={`${name} banner`}
                  className={classes.bannerDesktop}
                  resolveConfig={{
                    preset: presetMap[type],
                    extension: desktopImage.extension,
                    name: desktopImage.basename,
                    width: desktopImage.width,
                    height: desktopImage.height,
                    productName: name,
                  }}
                  hasLazyLoad={!!i}
                  {...sizes.desktop}
                />
                <Image
                  layout="fill"
                  objectFit="contain"
                  alt={`${name} mobile banner`}
                  className={classes.bannerMobile}
                  resolveConfig={{
                    preset: presetMap.SMALL,
                    extension: mobileImage.extension,
                    name: mobileImage.basename,
                    width: mobileImage.width,
                    height: mobileImage.height,
                    productName: name,
                  }}
                  hasLazyLoad={!!i}
                  hasPreload={!i}
                  {...sizes.mobile}
                />
              </PossibleLink>
            )
        )}
      </Slider>
    </div>
  );
};

export default PromotionBanners;
