import classNames from 'classnames';

import Typography from '@components/atoms/Typography';

import classes from './AdvantageItem.module.scss';
import Props from './AdvantageItem.types';

const AdvantageBox = ({
  header,
  description,
  icon: Icon,
  size = 'sm',
  center = true,
  centerDescription = true,
  objectFit = 'fill',
}: Props): JSX.Element => {
  const isSmall = size === 'sm';
  const iconSize = {
    sm: {
      width: 48,
      height: 48,
    },
    md: {
      width: 100,
      height: 60,
    },
    lg: {
      width: 180,
      height: 120,
    },
  };

  return (
    <div className={classNames(classes.wrapper, { [classes.center]: center })}>
      <div className={classNames(classes.icon, { [classes.small]: isSmall })}>
        <div className={classes.iconBox} style={objectFit === 'cover' ? iconSize[size] : undefined}>
          <Icon {...(objectFit === 'fill' ? iconSize[size] : {})} />
        </div>
      </div>
      <Typography variant={isSmall ? 'h3' : 'h1'} renderAs="div" className={classes.title}>
        {header}
      </Typography>
      <Typography
        variant={isSmall ? 'h4' : 'h2'}
        renderAs="div"
        className={classNames(classes.description, { [classes.centerDescription]: centerDescription })}
      >
        {description}
      </Typography>
    </div>
  );
};

export default AdvantageBox;
