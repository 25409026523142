import { useContext } from 'react';
import Image from '@next/image';

import { FilesContext } from '@providers/FilesProvider';
import Typography from '@components/atoms/Typography';
import { FormattedMessage } from 'utils/intl';

import classes from './PremiumSerwerBanner.module.scss';
import messages from './PremiumSerwerBanner.messages';

const PremiumSerwerBanner = () => {
  const { getPageImageConfigs } = useContext(FilesContext);
  const [image] = getPageImageConfigs(['servers']);

  return (
    <div className={classes.wrapper}>
      <div className={classes.leftSide}>
        <Typography variant="h1" className={classes.header}>
          <FormattedMessage {...messages.header} />
          <div className={classes.divider} />
        </Typography>
        <Typography variant="h4" renderAs="div">
          <FormattedMessage {...messages.description} />
        </Typography>
      </div>
      <div className={classes.image}>
        {image && (
          <Image
            alt={image.name || ''}
            display="flex"
            layout="fill"
            objectFit="contain"
            resolveConfig={image}
            hasLazyLoad={false}
            hasPreload
            width={640}
          />
        )}
      </div>
    </div>
  );
};

export default PremiumSerwerBanner;
