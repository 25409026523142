import config from 'config';

const { routes } = config;

export const trustedUsLimitMap: Record<string, 12 | 18> = {
  [routes.home.href]: 12,
  [routes.educationZone.href]: 12,
  [routes.publicSector.href]: 18,
  [routes.complexDelivery.href]: 18,
};
