import { createContext } from 'react';

import { MappedCart } from 'types/MappedCart';
import { defaultCart } from 'consts/cart';

type CartContextType = {
  cart: MappedCart;
  isLoading: boolean;
  onAddToCart: (variantId: number, quantity: number, complementaryProducts?: number[]) => void;
  onAddComplementaryToCart: (variantId: number, complementaryVariantId: number) => void;
  onDeleteFromCart: (id: number) => void;
  onUpdateOrderItem: (id: number, quantity: number) => void;
  onClearOrderItems: () => void;
};

export const CartContext = createContext<CartContextType>({
  cart: defaultCart,
  isLoading: true,
  onAddToCart: async () => {},
  onAddComplementaryToCart: async () => {},
  onDeleteFromCart: async () => {},
  onUpdateOrderItem: async () => {},
  onClearOrderItems: async () => {},
});
