/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['left', 'right', 'disabled'] }] */
import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import ArrowIcon from 'assets/icons/arrow.svg';

import classes from './PromotionBannersArrow.module.scss';

type Props = {
  variant: 'left' | 'right';
  className?: string;
  onClick: MouseEventHandler<HTMLElement>;
};

const PromotionBannersDots = ({ variant, className, onClick }: Props): JSX.Element => (
  <div className={classNames(classes.arrow, classes[variant], className)} onClick={onClick}>
    <ArrowIcon className={classes.arrowIcon} />
  </div>
);

export default PromotionBannersDots;
