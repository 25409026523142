import { defineMessages } from 'utils/intl';

export default defineMessages({
  specializedPartnerHeader: 'Wyspecjalizowany partner IT dla instytucji i biznesu - {appName}',
  specializedPartnerDescription:
    '{appName} to więcej niż sklep komputerowy dla firm i instytucji. Oferujemy o wiele więcej niż biznesowe laptopy, komputery dla firm, wydajne stacje robocze, monitory do pracy, akcesoria i oprogramowanie biurowe dla firm.\nW {appName} dostarczamy firmom i instytucjom skalowalne i wydajne rozwiązania IT: serwery rack, serwery tower, macierze, przełączniki sieciowe, urządzenia zabezpieczenia infrastruktury IT oraz backup. W {appName} zapewniamy firmom i instytucjom dostęp do sprawdzonych rozwiązań IT renomowanych producentów - HP, Lenovo, Dell, HPE, Fujitsu, Microsoft, Samsung, Fortinet. W sklepie IT dla instytucji i firm {appName} sprzedajemy wyłącznie legalny sprzęt IT z polskiej dystrybucji, objęty wsparciem technicznym i pełną gwarancją producenta.',
  richOfferHeader: 'Szeroka oferta IT dla firm i instytucji - {appName}',
  richOfferDescription:
    'Jesteśmy oficjalnym partnerem największych producentów IT, na {appName} oferujemy firmom i instytucjom nie tylko sprzęt IT - komputery dla biznesu, markowe stacje robocze, laptopy dla firm, profesjonalne monitory, oprogramowanie biurowe, serwery tower, serwery rack, storage, switche, backup IT i security IT od renomowanych producentów - Dell, Lenovo, HPE, HP, Fortinet, Microsoft, Veeam, Fujitsu czy Samsung. Proponujemy także usługi modernizacji, wdrożenia i utrzymania infrastruktury IT w firmie lub instytucji - usługi projektowania, modernizacji, integracji i zarządzania środowiskiem IT, np. wirtualizacja, backup, instalacja i aktualizacja oprogramowania czy migracja danych. Zapewniamy wsparcie zespołu doradców i inżynierów IT, uzyskujemy specjalne ceny projektowe dla firm i instytucji oraz dostarczamy legalny sprzęt z oficjalnej dystrybucji, z pełnym wsparciem serwisowym i gwarancyjnym producenta.',
  factoryNewHeader: 'Tylko fabrycznie nowe produkty na gwarancji - {appName}',
  factoryNewDescriptionFirstPart:
    'Wszystkie komputery biznesowe, laptopy dla biznesu, stacje robocze dla firm, oprogramowanie i akcesoria, monitory dla firm, storage, serwery, switche, urządzenia backup IT i security IT sprzedawane na {appName} to oryginalne, nowe sprzęty w fabrycznych opakowaniach, pochodzące z oficjalnej dystrybucji w naszym regionie, objęte pełną obsługą gwarancyjną i serwisową producenta.',
  factoryNewDescriptionSecondPart:
    'Wszystkie elementy zmieniane w trakcie konfiguracji sprzętu na {appName} - np. dyski twarde, procesory, pamięci RAM to oryginalne, fabrycznie nowe podzespoły podlegające gwarancji producenta sprzętu i objęte pełną gwarancją sprzętową. Wszystkie modyfikacje wynikające ze specyfikacji sprzętu na {appName} odbywają się w fabryce producenta i podlegają fabrycznej gwarancji.',
  freeDeliveryHeader: 'Na {appName} bezpłatna dostawa kurierem i natychmiastowa wysyłka',
  freeDeliveryDescription:
    'Firmy i instytucje kupujące na {appName} laptopy, komputery biznesowe, oprogramowanie i akcesoria, monitory dla profesjonalistów, profesjonalne stacje robocze, urządzenia bezpieczeństwa i backupu IT, switche, macierze, serwery rack i serwery tower otrzymują bezpłatną wysyłkę kurierem dla każdego zamówienia. Przesyłki wysyłamy natychmiast po zaksięgowaniu płatności, wszystkie produkty sprzedawane na {appName} są dostępne do zakupu i wysyłki.\nWszystkie zakupy zrealizowane na {appName} wysyłamy z Polski, najczęściej z dostawą w 24 godziny robocze.',
  preorderHeader: 'Przedsprzedaż - kup na {appName} w preorder',
  preorderDescription:
    'Niektóre komputery, stacje robocze, laptopy, monitory lub oprogramowanie dostępne są na {appName} w ramach przedsprzedaży - produkty do zamówienia pre-order są zawsze oznaczone i zawierają planowany czas realizacji zamówienia przedsprzedażowego.\nJeśli poszukujesz do firmy lub instytucji nowości sprzętowych - premierowych laptopów, najnowszych komputerów, nowoczesnych stacji roboczych skorzystaj z przedsprzedaży na {appName}',
  preferredOfficialPartnerHeader: 'Preferowany Oficjalny Partner Dell w Polsce - {appName}',
  preferredOfficialPartnerDescription:
    'Sklep online z Dell dla firm i instytucji - {appName} oficjalny Tytanowy Partner Dell Technologies w Polsce. {appName} oferuje biznesowe komputery Dell (Dell Inspiron, Dell Optiplex, Dell Vostro), laptopy Dell dla firm (Dell Inspiron, Dell Vostro, Dell Latitude), wydajne stacje robocze Dell Precision, monitory Della do pracy i dla profesjonalistów, akcesoria Dell, oprogramowanie biurowe Office, systemy operacyjne Microsoft Windows i Windows Server. Jako Oficjalny Partner Dell w Polsce w {appName} dostarczamy firmom i instytucjom skalowalne i wydajne rozwiązania IT oparte o hardware Della - serwery rack Dell PowerEdge, serwery tower Dell Power Edge, macierze Dell Unity. W sklepie z Dell dla instytucji i firm {appName} Dell Technologies Titanium Partner sprzedajemy wyłącznie legalny sprzęt Dell Technologies z polskiej dystrybucji, objęty wsparciem technicznym i pełną gwarancją producenta Dell Technologies.',
  hardwareForCompaniesHeader: 'Sprzęt Dell dla firm i instytucji - {appName}',
  hardwareForCompaniesDescription:
    'Jesteśmy oficjalnym partnerem Dell Technologies w Polsce - Tytanowy Partner Dell, w {appName} dostarczamy instytucjom i firmom legalny sprzęt IT Dell - komputery Della dla biznesu, markowe i wydajne stacje robocze Della Dell Precision, laptopy Della dla firm, profesjonalne monitory Dell, oprogramowanie biurowe Office, serwery tower Dell Power Edge, serwery rack Dell PowerEdge, storage Dell Unity. Proponujemy także usługi modernizacji, wdrożenia i utrzymania infrastruktury IT w firmie lub instytucji - usługi projektowania, modernizacji, integracji i zarządzania środowiskiem IT, np. wirtualizacja, backup, instalacja i aktualizacja oprogramowania czy migracja danych.\nJako preferowany oficjalny partner Dell Technoogies Titanium Partner w Polsce zapewniamy wsparcie zespołu doradców i inżynierów IT, uzyskujemy specjalne ceny projektowe dla firm i instytucji oraz dostarczamy legalny sprzęt IT Della z oficjalnej dystrybucji, z pełnym wsparciem serwisowym i gwarancyjnym Dell Technologies i Dell EMC.',
  dellFactoryNewHeader: 'Tylko fabrycznie nowe produkty Dell na gwarancji - {appName}',
  dellFactoryNewDescriptionFirstPart:
    'Wszystkie komputery dla firm Dell, laptopy biznesowe Dell, stacje robocze Dell dla profesjonalistów, oprogramowanie i akcesoria, monitory Dell dla firm, storage Dell, serwery rack Dell, serwery tower Dell sprzedawane przez oficjalnego partnera Dell Technologies w Polsce - Tytanowego Partnera Dell Technologies {appName} to oryginalne, nowe sprzęty Dell w fabrycznych opakowaniach, pochodzące z oficjalnej dystrybucji Dell Technologies i DellEMC w naszym regionie, objęte pełną obsługą gwarancyjną i serwisową Della.',
  dellFactoryNewDescriptionSecondPart:
    'Wszystkie elementy zmieniane w trakcie konfiguracji sprzętu na {appName} - np. dyski twarde, procesory, pamięci RAM to oryginalne, fabrycznie nowe podzespoły podlegające gwarancji producenta sprzętu i objęte pełną gwarancją sprzętową. Wszystkie modyfikacje wynikające ze specyfikacji sprzętu na {appName} odbywają się w fabryce producenta i podlegają fabrycznej gwarancji Dell.',
  dellowoFreeDeliveryHeader: 'Bezpłatna dostawa kurierem, natychmiastowa wysyłka - {appName}',
  dellowoFreeDeliveryDescription:
    'Laptopy firmowe Dell, komputery dla firm Dell, oprogramowanie i akcesoria Dell, monitory Dell dla profesjonalistów, wydajne markowe stacje robocze Dell, macierze Dell, serwery Dell rack i serwery Dell tower kupowane przez firmy i instytucje na {appName} objęte są bezpłatną wysyłką kurierem dla każdego zamówienia. U oficjalnego Tytanowego Partnera Dell Technologies w Polsce {appName} przesyłki wysyłne są natychmiast po zaksięgowaniu płatności. Wszystkie produkty Dell sprzedawane na {appName} są dostępne do zakupu i wysyłki z Polski, najczęściej z dostawą w 24 godziny robocze.',
  dellowoPreorderHeader: 'Przedsprzedaż {appName} - kup u Oficjalnego Partnera Dell w Polsce',
  dellowoPreorderDescription:
    'Premierowe laptopy Della, najnowsze stacje robocze Della, premierowe komputery Della, najnowsze monitory Della, premierowe serwery Della kupisz na {appName} w ramach przedsprzedaży - są zawsze oznaczone i zawierają planowany czas realizacji zamówienia przedsprzedażowego. Jeśli potrzebujesz do firmy lub instytucji nowości Dell - premierowych laptopów Dell, najnowszych komputerów Della, nowoczesnych serwerów Dell, premierowych stacji roboczych Dell skorzystaj z przedsprzedaży na {appName}',
  titanPartnerHeader: 'Wieloletni Tytanowy Partner Dell w Polsce - Dell Technologies Titanium Partner',
  titanPartnerDescription:
    'W {appName} jako preferowany oficjalny partner Dell w Polsce - Dell Technologies Titanium Partner sprzedajemy wyłącznie legalne urządzenia Dell z oficjalnego kanału dystrybucji Dell Technologies i Dell EMC, podlegające w Polsce pełnej obsłudze serwisowej i gwarancyjnej Dell. Od 2009 roku jesteśmy sklepem online z laptopami Dell, desktopami Dell, PC Dell, stacjami roboczymi Dell, storage Dell, serwerami Dell, monitorami Dell, akcesoriami Dell, dla B2B i instytucji. Kilkanaście osób codziennie zapewnia tu sprawną i efektywną obsługę, dzięki czemu w 99,7% zamówienia realizujemy z dostawą w poniżej 24h! Zrealizowaliśmy już powyżej 45 213 zamówień dla ponad 17 783 klientów - sprawdź jak sprawnie obsłużymy Ciebie.',
  welcomeFirewallHeader:
    'Witamy serdecznie na {appName} - Twoim niezawodnym partnerze w dziedzinie firewall i bezpieczeństwa sieciowego!',
  welcomeFirewallDescriptionFirstPart:
    'W naszym sklepie online, specjalizującym się w sprzedaży sprzętu firewall, oferujemy szeroki wybór najnowocześniejszych rozwiązań do zabezpieczania Twojej sieci. Nasz asortyment obejmuje firewalle renomowanych producentów, zapewniające nie tylko solidną ochronę, ale także pełną kontrolę nad ruchem w Twojej sieci. Jako doświadczony dostawca firewall, cieszymy się zaufaniem klientów dzięki naszemu ponad 30-letniemu doświadczeniu w dziedzinie IT.\nNasz zespół wysoko wykwalifikowanych ekspertów, posiadających liczne certyfikaty z zakresu firewall, zawsze jest gotów, aby doradzić i dostosować rozwiązania do indywidualnych potrzeb Twojej firmy.',
  welcomeFirewallDescriptionSecondPart:
    'W {appName} nieustannie rozwijamy naszą ofertę, aby dostarczyć Ci nie tylko sprzętu firewall najwyższej jakości, ale także kompleksowych rozwiązań obejmujących bramy bezpieczeństwa, monitoring ruchu sieciowego i wiele innych. Oferujemy także szkolenia i wsparcie techniczne, abyś mógł pełni korzystać z potencjału swojego firewall. Dołącz do setek zadowolonych klientów, którzy zaufali {appName}, aby chronić swoją sieć. Oferujemy nie tylko produkty firewall, ale także gwarancję bezpieczeństwa i spokoju ducha w dynamicznym świecie IT. Razem z nami, Twój firewall stanie się nie tylko ochroną, ale także strategicznym elementem bezpieczeństwa Twojej firmy!',
  firewallSpecializedHeader: 'Wyspecjalizowany Oficjalny Partner rozwiązań Firewall w Polsce - {appName}',
  firewallSpecializedDescription:
    'Sklep online z rozwiązaniami firewall dla firm i instytucji - {appName}. Oficjalny Partner czołowych producentów urządzeń firewall w Polsce. {appName} oferuje rozwiązania sieciowe w tym produkty Firewall, Switche oraz Access Pointy. Jako Oficjalny Partner m.in. Fortinet w Polsce w {appName} dostarczamy firmom i instytucjom skalowalne i wydajne rozwiązania sieciowe oparte o urządzenia Firewall, urządzenia Firewall z funkcją Access Pointa, przełączniki sieciowe, czy też urządzenia typu Access Point. W sklepie dla instytucji i firm {appName} sprzedajemy wyłącznie legalny sprzęt z polskiej dystrybucji, objęty wsparciem technicznym i pełną gwarancją producenta.',
  firewallEquipmentHeader: 'Sprzęt firewall dla firm i instytucji - {appName}',
  firewallEquipmentDescription:
    'Jesteśmy Oficjalnym Partnerem producentów rozwiązań firewall. W {appName} dostarczamy instytucjom i firmom legalne produkty, w tym firewall, switche oraz access pointy. Proponujemy także usługi modernizacji, wdrożenia i utrzymania infrastruktury IT w firmie lub instytucji - usługi projektowania, modernizacji, integracji i zarządzania środowiskiem IT, np. w zakresie ochrony firewall oraz dostępu do sieci.\nZapewniamy wsparcie zespołu certyfikowanych doradców i inżynierów IT, uzyskujemy specjalne ceny projektowe dla firm i instytucji oraz dostarczamy legalny sprzęt z oficjalnej polskiej dystrybucji, z pełnym wsparciem serwisowym i gwarancyjnym producentów.',
  firewallGuaranteeHeader: 'Tylko fabrycznie nowe produkty firewall na gwarancji - {appName}',
  firewallGuaranteeDescription:
    'Wszystkie urządzenia, w tym firewall dla firm, switche dla firm, access pointy dla firm, sprzedawane przez Oficjalnego Partnera producentów firewall w Polsce. {appName} to oryginalne, nowe sprzęty czołowych producentów m.in. Fortinet w fabrycznych opakowaniach, pochodzące z oficjalnej dystrybucji, objęte pełną obsługą gwarancyjną i serwisową.',
  firewallShipmentHeader: 'Bezpłatna dostawa kurierem, natychmiastowa realizacja - {appName}',
  firewallShipmentDescription:
    'Firewall, Access Point, Switche kupowane przez firmy i instytucje na {appName} objęte są bezpłatną wysyłką kurierem dla każdego zamówienia. Jako Oficjalny Partner czołowych producentów rozwiązań firewall w Polsce gwarantujemy, że zamówienia realizowane są natychmiast po zaksięgowaniu płatności. Wszystkie produkty sprzedawane na {appName} są dostępne do zakupu. Czas realizacji dla produktów dostępnych w magazynie wynosi zazwyczaj 24 godziny. Produkty dostępne na zamówienie dostarczane są w terminie ustalonym z Klientem.',
  firewallSupportHeader: 'Usługi wdrożenia i wsparcia technicznego - {appName}',
  firewallSupportDescription:
    'Jako COCON® działamy w obszarze technologii informatycznych od 1996 roku.\nW ramach tej marki zajmujemy się projektowaniem i implementacją sieci oraz zapewnianiem bezpieczeństwa sieciowego w oparciu o rozwiązania firewall; projektowaniem, wdrażaniem i utrzymaniem systemów informatycznych, wirtualizacją środowisk IT; projektowaniem i implementacją rozwiązań do tworzenia kopii zapasowych; konsultingiem technologicznym oraz outsourcingiem IT. Do chwili obecnej zrealizowaliśmy terminowo ponad 60 000 zleceń dla ponad 18 000 firm i instytucji.\nRocznie dostarczamy naszym klientom ponad 30 000 jednostek sprzętu IT oraz realizujemy złożone projekty planowania, dostarczania, wdrażania i modernizacji infrastruktury IT, kładąc jednocześnie nacisk na maksymalizację bezpieczeństwa sieci wykorzystując w tym celu możliwości jakie daje technologia firewall, obejmujące urządzenia końcowe oraz rozwiązania klasy enterprise.',
  firewallPartnersHeader: 'Statusy partnerskie i certyfikaty - {appName}',
  firewallPartnersDescription:
    'Jesteśmy oficjalnym partnerem światowych producentów technologii informatycznych, znanych m.in. z rozwiązań typu firewall w tym parterem Fortinet (Advanced Partner Integrator) z dodatkowymi specjalizacjami w obszarze SD-WAN i LAN Edge oraz SD-Branch. Nasi eksperci w technologii firewall posiadają m.in. certyfikaty: Fortinet Certified Fundamentals (FCF), Fortinet Certified Associate (FCA), Fortinet Certified Professional Network Security (FCP Network Security) oraz Fortinet Certified Solution Specialist Network Security (FCSS Network Security). W uznaniu dotychczasowej współpracy zostaliśmy wyróżnieni tytułem Fortinet SMB Hero 2020.',
});
