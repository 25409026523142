import SkeletonLoader from '@components/atoms/SkeletonLoader';

import classes from './RecommendedProductsSkeleton.module.scss';

const RecommendedProductsSkeleton = (): JSX.Element => (
  <div className={classes.skeleton}>
    <SkeletonLoader iconWidth={260} />
  </div>
);

export default RecommendedProductsSkeleton;
