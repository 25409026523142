import { useEffect, useContext, CSSProperties } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';

import { ItemListType, ProductItem } from 'types/Analytics';
import { getVariantsPromotedRoute } from 'apiRoutes/variants';
import { CartContext } from '@providers/CartProvider';
import { getViewItemListPayload } from 'utils/analytics/payload/viewItemList';
import createStyleVariables from 'utils/createStyleVariables';
import { useClientSideProps } from 'hooks/useClientSideProps';
import useTrack from 'hooks/useTrack';
import useFetch from 'hooks/useFetch';

import Skeleton from './components/RecommendedProductsSkeleton';
import { Props as ViewProps } from './RecommendedProducts';

type Props = Omit<ViewProps, 'onAddToCart' | 'products'> & {
  className?: string;
  listType: ItemListType;
  recommendedProductsLength: number;
};

const RecommendedProductsView = dynamic(() => import('./RecommendedProducts'), {
  loading: () => <Skeleton />,
  ssr: false,
});

const itemHeight = 323;
const mobileHeaderHeight = 63;
const headerHeight = 87;
const itemsGapHeight = 16;

const getStyle = (itemsCount: number): CSSProperties => {
  const tabletRowsCount = itemsCount >= 8 ? itemsCount - 3 : Math.ceil(itemsCount / 2);
  const desktopRowsCount = Math.ceil(itemsCount / 4);
  const mobileAdditionalHeight = (itemsCount - 1) * itemsGapHeight + mobileHeaderHeight;
  const tabletAdditionalHeight = (tabletRowsCount - 1) * itemsGapHeight + headerHeight;
  const desktopAdditionalHeight = (desktopRowsCount - 1) * itemsGapHeight + headerHeight;

  return createStyleVariables({
    recommendedProductsSkeletonHeight: `${itemsCount * itemHeight + mobileAdditionalHeight}px`,
    recommendedProductsSkeletonHeightSmall: `${tabletRowsCount * itemHeight + tabletAdditionalHeight}px`,
    recommendedProductsSkeletonHeightMedium: `${desktopRowsCount * itemHeight + desktopAdditionalHeight}px`,
  });
};

const RecommendedProducts = ({ listType, className, recommendedProductsLength, ...rest }: Props): JSX.Element => {
  const router = useRouter();
  const { onAddToCart } = useContext(CartContext);
  const { trackEvent } = useTrack();
  const { ref, inView } = useInView({
    triggerOnce: true,
    initialInView: false,
  });
  const [getVariantsPromoted] = useFetch(getVariantsPromotedRoute);

  const {
    data: { products },
  } = useClientSideProps(
    {
      products: async () => {
        if (inView) {
          const { data } = await getVariantsPromoted();
          return data?.variants || [];
        }

        return undefined;
      },
    },
    ['products'],
    [inView]
  );

  useEffect(() => {
    if (products?.length) {
      const mappedProducts: ProductItem[] = products.map((product) => ({
        grossPricePerUnit: product.grossPrice.amount, // todo check ProductItem
        pricePerUnit: product.price,
        variant: product,
        quantity: 1,
      }));
      trackEvent('e-commerce', 'view_item_list', getViewItemListPayload(listType, router.asPath, mappedProducts));
    }
  }, [listType, products]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={className}
      ref={ref}
      style={recommendedProductsLength ? getStyle(recommendedProductsLength) : undefined}
    >
      {products?.length ? (
        <RecommendedProductsView products={products} {...rest} onAddToCart={onAddToCart} />
      ) : (
        <Skeleton />
      )}
    </div>
  );
};

export default RecommendedProducts;
