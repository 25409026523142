import { useContext } from 'react';

import { ConfigContext, getShopKey } from '@providers/ConfigProvider';
import SearchEngineText from '@components/SearchEngineText';

import { textMap } from './ShopSearchEngineText.consts';

const ShopSearchEngineText = (): JSX.Element => {
  const { shopId } = useContext(ConfigContext);

  const texts = textMap[getShopKey(shopId)];

  return <SearchEngineText texts={texts} />;
};

export default ShopSearchEngineText;
