import { ReactNode } from 'react';
import classNames from 'classnames';

import Message from 'types/Message';
import Configuration1Icon from 'assets/icons/configuration-1.svg';
import Configuration2Icon from 'assets/icons/configuration-2.svg';
import Configuration3Icon from 'assets/icons/configuration-3.svg';
import Configuration4Icon from 'assets/icons/configuration-4.svg';
import Configuration5Icon from 'assets/icons/configuration-5.svg';
import Arrow from 'assets/icons/arrow.svg';
import Typography from '@components/atoms/Typography';
import MessageWithBold from '@components/atoms/MessageWithBold';
import { FormattedMessage, MessageDescriptor } from 'utils/intl';
import { scrollTo } from 'utils/scroll';
import createStyleVariables from 'utils/createStyleVariables';

import messages from './ConfiguratorProcess.messages';
import classes from './ConfiguratorProcess.module.scss';

type Item = {
  id: string;
  header: Message;
  description: MessageDescriptor;
  longDescription: MessageDescriptor;
  icon: ReactNode;
};

const items: Item[] = [
  {
    id: 'configuration',
    header: <FormattedMessage tagName="b" {...messages.configuration} />,
    description: messages.configurationDescription,
    longDescription: messages.configurationLongDescription,
    icon: <Configuration1Icon />,
  },
  {
    id: 'pricing',
    header: <FormattedMessage tagName="b" {...messages.pricing} />,
    description: messages.pricingDescription,
    longDescription: messages.pricingLongDescription,
    icon: <Configuration2Icon />,
  },
  {
    id: 'production',
    header: <FormattedMessage tagName="b" {...messages.production} />,
    description: messages.productionDescription,
    longDescription: messages.productionLongDescription,
    icon: <Configuration3Icon />,
  },
  {
    id: 'shipment',
    header: <FormattedMessage tagName="b" {...messages.shipment} />,
    description: messages.shipmentDescription,
    longDescription: messages.shipmentLongDescription,
    icon: <Configuration4Icon />,
  },
  {
    id: 'implementation',
    header: (
      <MessageWithBold
        message={messages.implementation}
        values={{ bold: <FormattedMessage {...messages.implementationBold} /> }}
      />
    ),
    description: messages.implementationDescription,
    longDescription: messages.implementationLongDescription,
    icon: <Configuration5Icon />,
  },
];

type Props = {
  className?: string;
  size?: 'small' | 'big';
  withId?: boolean;
  withLinkToMore?: boolean;
};

const ConfiguratorProcess = ({ className, size = 'small', withId, withLinkToMore }: Props) => (
  <div className={classNames(classes[size], className)}>
    {items.map(({ id, header, description, longDescription, icon }, index) => (
      <div
        key={id}
        id={withId ? id : undefined}
        className={classes.item}
        style={createStyleVariables({ zIndex: items.length - index })}
      >
        {size === 'small' && <div className={classes.arrowBackground} />}
        <Typography
          variant="h1"
          renderAs="div"
          weight="400"
          className={classNames(classes.header, { [classes.headerBackground]: size === 'small' })}
        >
          <div className={classNames(classes.icon, { [classes.desktop]: size === 'small' })}>{icon}</div>
          <Typography className={classes.headerText} variant={size === 'big' ? 'h2' : 'h4'} renderAs="div">
            <span className={classes.indicator}>{index + 1}.</span>
            {header}
          </Typography>
        </Typography>
        <div>
          {size === 'small' && (
            <Typography className={classes.mobileHeader} variant="h4" renderAs="div">
              <div className={classes.icon}>{icon}</div>
              <div>
                <span className={classes.indicator}>{index + 1}.</span>
                {header}
              </div>
            </Typography>
          )}
          <Typography
            variant={size === 'small' ? 'body2' : 'h2'}
            className={classNames({ [classes.smallDescription]: size === 'small' })}
            lineHeight={size === 'small' ? '18px' : undefined}
            renderAs="div"
          >
            <FormattedMessage {...description} />
          </Typography>
          {size === 'big' && (
            <Typography variant="h4" renderAs="div" className={classes.longDescription}>
              <FormattedMessage {...longDescription} />
            </Typography>
          )}
          {withLinkToMore && (
            <Typography variant="body2" renderAs="div" className={classes.more} onClick={() => scrollTo(`#${id}`)}>
              <FormattedMessage {...messages.more} />
              <Arrow height={10} />
            </Typography>
          )}
        </div>
      </div>
    ))}
  </div>
);

export default ConfiguratorProcess;
