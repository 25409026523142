import { defineMessages } from 'utils/intl';

export default defineMessages({
  mainHeader: 'Jesteśmy wyspecjalizowanym partnerem IT dla instytucji i biznesu',
  mainDescription: `Zapewniamy firmom i instytucjom dostęp do sprawdzonych rozwiązań IT renomowanych producentów, 
    dostarczamy legalny sprzęt z oficjalnej dystrybucji i robimy to terminowo i zgodnie z umową.`,
  experienceHeader: 'Doświadczenie',
  originalHeader: 'Oryginalne podzespoły',
  convidenceHeader: 'Pewność zakupu',
  securityHeader: 'Bezpieczeństwo',
  supportHeader: 'Wsparcie',
  experienceDescription: `Dostarczamy zarówno pojedyncze serwery jak kompletną infrastrukturę rozbudowanych serwerowni. `,
  originalDescription: `Wszystkie modyfikacje sprzętu wynikające ze specyfikacji odbywają się w fabryce producenta i podlegają fabrycznej gwarancji`,
  convidenceDescription: `Sprzęt dostępny w naszym sklepie jest fabrycznie nowy i pochodzi z oficjalnej polskiej dystrybucji, gwarantując najwyższą jakość i pewność zakupu.`,
  securityDescription: `Jesteśmy oficjalnym partnerem największych producentów sprzętu IT, który podlega pełnej obsłudze serwisowej i gwarancyjnej producenta.`,
  supportDescription: `Na każdym etapie procesu sprzedaży świadczymy pełne wsparcie techniczne: począwszy od przygotowania niezbędnej dokumentacji, poprzez wdrożenie, aż po obsługę powdrożeniową.`,
});
