import { EventParams, ItemListType, ProductItem } from 'types/Analytics';
import { getItems } from 'utils/analytics';

export const getViewItemListPayload = (
  listType: ItemListType,
  listTarget: string,
  products: ProductItem[]
): EventParams['view_item_list'] => ({
  item_list_id: listType,
  list_target: listTarget,
  items: getItems(products),
});
