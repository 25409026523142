/**
 * @note **Warning:** This cannot be a component, as it's passed to Next.js's <Head>, components won't be rendered properly
 */
const getStructuredData = <T extends object>(data: T): JSX.Element => (
  <script
    type="application/ld+json"
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: JSON.stringify(data, null, 2),
    }}
  />
);

export default getStructuredData;
