import { BannerTypeId } from 'types/models/Banner';
import { GetBannersResults } from 'graphql/queries/GetBanners.query';
import { Promotion } from '@components/PromotionBanners';
import getUniqueValues from 'utils/getUniqueValues';

const getMappedPromotions = (
  banners: GetBannersResults['banners'],
  desktopBannerTypeId: BannerTypeId,
  categoryId?: number
): Promotion[] => {
  const promotions = getUniqueValues(banners, 'name')
    .filter(({ category }) => category?.id === categoryId)
    .map(({ id, name, url, openInNewTab }) => {
      const desktopImage = banners.find(
        ({ name: bannerName, bannerTypeId }) => bannerName === name && bannerTypeId === desktopBannerTypeId
      )?.image;
      const mobileImage = banners.find(
        ({ name: bannerName, bannerTypeId }) => bannerName === name && bannerTypeId === 'SMALL'
      )?.image;
      return { id, name, url, desktopImage, mobileImage, openInNewTab };
    });
  return promotions;
};

export default getMappedPromotions;
